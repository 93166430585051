.entry-box{
    margin-top:120px ;
    padding: 0px;

}

.grid{
    display: grid;
    grid-template-columns: 150px 600px auto ;
    grid-column: 2;
    padding: 5px; 
}
 

.card-image{
    border-radius: 50px;

}

.box{
    
   text-align: left;
    border-radius: 20px;
    margin: 20px;
    padding: 15px;
    box-shadow: 0px 0px 50px -10px;

}
.box h1{
    font-size: 20px;
    padding: 0px;
}

.box-line{
    width: 50px;
    text-align: left;
    align-items: flex-start;
    padding: 0px;
   
}

.card-list{
    list-style: none;
    display: flex;
    padding: 0px;
    
}
.card-list li{
    
        padding:5px;

        overflow:hidden;
        font-size: 15px;
        color: grey;
     
    
}