.Footer{
    background-color: #16181D;
    color:white;
    text-align: left;
      display: grid;
      grid-template-columns: 200px 300px 300px;
    font-size: 18px;
    font-family:Arial, Helvetica, sans-serif;
    padding-left: 100px;
    padding-right: 35%;
    padding-top: 50px;
    padding-bottom: 50px;
    }

    .image{
        width: 150px;
    }

    .copyrights{
       color: rgb(153, 153, 153);
       font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       font-size: 16px;
    }