.content-area{
    padding-top: 150px;
    
    
    height: 100%;
    background-color: white;
    padding-left: 50px;
    padding-right: 0px;
}

.title{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 65px;
    color:rgb(40, 44, 52);
}

.title-2{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 35px;
    font-weight: 700;
    line-height: 65px;
}


.title-3{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 65px;
}


.sub-title{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 24px;
    font-weight: 200;
    line-height: 40.6px;
}

.paragraph{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 28.9px;
    

}

.main-content{
padding: 0px 35% 0px 50px;
}