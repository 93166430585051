
.sidebar{

    overflow:scroll;
    position:fixed;
    overflow: visible;
    right: 0px;
    top: 0px;


    
    background-color: #F7F7F7;
    width: 350px;
    padding-left: 30px;
   
    padding-top:180px;
    height: 100%;

    
}

.sidebar-toggles{
    
    font-size: 14px;
    line-height: 42px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
   
}

summary.sidebar-toggles:hover{
    cursor: hand;
}


.sidebar-item{
    font-size: 16px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 18.4px;
    margin: 10px 0px 0px 0px;
}

.sidebar-item a{
    cursor: hand;
    padding-left: 15px;
    text-decoration: none;
    color:rgb(26, 26, 26);
    font-size: 16px;
    font-weight: 400;
    border: solid #F7F7F7;
    border-width: 0px 0px 0px 3px;
    font-family: Arial, Helvetica, sans-serif;
}


.sidebar-item a:active{
    text-decoration: none;
    color:rgb(26, 26, 26);
    font-size: 16px;
    font-weight: 600;
    border: solid #61DAFB;
    border-width: 0px 0px 0px 3px;
    font-family: Arial, Helvetica, sans-serif;
}

.sidebar-item a:hover{
    cursor: hand;
    text-decoration: none;
    color:rgb(26, 26, 26);
    font-size: 16px;
    font-weight: 600;
    border: solid #61DAFB;
    border-width: 0px 0px 0px 3px;
    font-family: Arial, Helvetica, sans-serif;
}